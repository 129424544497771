<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <div class="c-row ui--mb-4">
      <!-- Coluna esquerda -->
      <div class="c-col-full sm:c-col ui-mb-4">
        <!-- Título e subtitulo -->
        <div>
          <!-- Titulo -->
          <div class="ui-text-lg ui-font-bold">
            <span data-cy="titleText">Enviamos o email de redefinição</span>
          </div>

          <!-- Subtitulo -->
          <div class="ui-text-sm ui-mt-3">
            <span data-cy="messageText">Se o email estiver cadastrado, você receberá um link para redefinir a senha.</span>
          </div>
        </div>

        <!-- Botão -->
        <div class="ui-pt-4 ui-font-semibold">
          <router-link :to="{ name: 'login', query: { ...$route.query } }">
            <c-button
              data-cy="goToLoginButton"
              class="ui-w-full"
              size="lg"
            >
              Voltar para o login
            </c-button>
          </router-link>
        </div>
      </div>

      <!-- Coluna direita -->
      <div class="c-col-full sm:c-col c-d-flex ui-mb-4 md:ui--mb-2 c-horizontal-center">
        <!-- Ilustração -->
        <div class="ui--mb-8 c-d-flex self-end">
          <img
            src="@/assets/images/p-modal/success.svg"
            width="216px"
            alt="Ilustração"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  components: { CButton },
};
</script>
